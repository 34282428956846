import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  cancel2FAAction,
  getUserInfoAction,
  login2FAAction,
  loginAction,
  recoveryAction,
  registerAction,
  setUserDataAction,
  startLoadingAction,
  startUserSecuritySessionAction,
  updateUserInfoAction
} from '@/bus/user/actions'
import { IRootState } from '@/store/reducer'

export const useUser = () => {
  const dispatch = useDispatch()
  const {
    loading,
    loaded,
    errors,
    failed,
    data = {},
    is2FA = false
  } = useSelector(({ user }: IRootState) => user)

  const login = (props: object) => {
    const action = loginAction(props)
    dispatch(action)
  }

  const login2FA = (props: object) => {
    const action = login2FAAction(props)
    dispatch(action)
  }

  const register = (props: object) => {
    const action = registerAction(props)
    dispatch(action)
  }

  const recovery = (props: object) => {
    const action = recoveryAction(props)
    dispatch(action)
  }

  const startLoading = () => {
    const action = startLoadingAction({ loading: true })
    dispatch(action)
  }

  const getUserInfo = () => {
    const action = getUserInfoAction()
    dispatch(action)
  }

  const setUserData = (props: any) => {
    const action = setUserDataAction(props)
    dispatch(action)
  }

  const updateUserInfo = (props: any) => {
    const action = updateUserInfoAction(props)
    dispatch(action)
  }

  const cancel2FA = () => {
    const action = cancel2FAAction()
    dispatch(action)
  }

  const startUserSecuritySession = () => {
    const action = startUserSecuritySessionAction()
    dispatch(action)
  }

  const isAuthorized = useMemo(() => loaded && data?.id, [loaded, data])

  return {
    data,
    is2FA,
    login,
    login2FA,
    register,
    recovery,
    loading,
    loaded,
    errors,
    failed,
    startLoading,
    getUserInfo,
    startUserSecuritySession,
    isAuthorized,
    cancel2FA,
    setUserData,
    updateUserInfo
  }
}
